import React, { useEffect, useState } from 'react';
import { Box, TextField, CircularProgress, Typography, Tooltip, IconButton, Menu, MenuItem, Button } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { fetchS3FolderStructure, buildTree } from '../../../api/awsServicePreviewDocument';
import { MAX_NAME_LENGTH, NON_PARSABLE_FILES, truncateName } from '../../../utils/constants';
import { Buffer } from 'buffer';
import { useDispatch } from 'react-redux';
import { getTranslationVariantText } from '../../../utils/helper'; // Assuming this is your translation helper

const renderTree = (nodes, onFileClick, toggleFolder, handleEllipsisClick) => {
    const isFolder = !nodes.folderName.endsWith('.pdf');
    const isNameLong = nodes.folderName.length > MAX_NAME_LENGTH;
    const truncatedName = truncateName(nodes.folderName);
    const isNonParsable = NON_PARSABLE_FILES.includes(nodes.folderName); 

    return (
        <TreeItem
            key={nodes.id}
            itemId={nodes.id}
            label={
                <Box display="flex" alignItems="center">
                    <Tooltip title={isNameLong ? nodes.folderName : ''} placement="top-start">
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '1.2rem',
                                margin: 0,
                                padding: 0,
                                flexGrow: 1,
                                color: isNonParsable ? 'black' : 'inherit'
                            }}
                        >
                            {isFolder ? (
                                <FolderIcon
                                    sx={{ color: "#F8D775", marginRight: '8px', fontSize: '1.6rem' }}
                                />
                            ) : (
                                <DescriptionIcon
                                    sx={{ color: isNonParsable ? 'red' : '#254a9a', marginRight: '8px', fontSize: '1.6rem' }}
                                />
                            )}
                            {truncatedName}
                        </Typography>
                    </Tooltip>

                    {!isFolder && (
                        <IconButton
                            size="small"
                            onClick={(e) => handleEllipsisClick(e, nodes)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </Box>
            }
            onClick={isFolder ? () => toggleFolder(nodes.folderName) : () => onFileClick(nodes.link)}
            sx={{ '&:hover': { backgroundColor: '#f5f5f5', cursor: 'pointer' } }}
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node, onFileClick, toggleFolder, handleEllipsisClick)) : null}
        </TreeItem>
    );
};

const PreviewDocumentDataWrapper = () => {
    const [pdfLink, setPdfLink] = useState(null);
    const [folderTree, setFolderTree] = useState([]);
    const [openFolders, setOpenFolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);  // Menu anchor
    const [selectedPdf, setSelectedPdf] = useState(null);  // Store the selected PDF for chat
    const [isChatMode, setIsChatMode] = useState(false);  // Toggle between folder view and chat mode

    const navigate = useNavigate();
    const zoomPluginInstance = zoomPlugin();
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    useEffect(() => {
        const loadS3Data = async () => {
            try {
                const s3Objects = await fetchS3FolderStructure();
                const treeData = await buildTree(s3Objects);
                setFolderTree(treeData);
                setLoading(false);
            } catch (err) {
                setError("Failed to load data. Please try again.");
                setLoading(false);
            }
        };

        loadS3Data();
    }, []);

    const handleFileClick = (link) => {
        setPdfLink(link);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const toggleFolder = (folderName) => {
        if (openFolders.includes(folderName)) {
            setOpenFolders(openFolders.filter(folder => folder !== folderName));
        } else {
            setOpenFolders([...openFolders, folderName]);
        }
    };

    const handleEllipsisClick = (event, pdf) => {
        setAnchorEl(event.currentTarget);  // Open the menu at the ellipsis location
        setSelectedPdf(pdf);  // Set the selected PDF for further action
    };

    const handleChatWithPdf = async () => {
        setAnchorEl(null);  // Close the menu

        // Check if `selectedPdf` has a valid link
        if (selectedPdf && selectedPdf.link) {
            const encodedUrl = Buffer.from(selectedPdf.link).toString('base64');
            navigate(`/guru/preview-document/chat/${encodedUrl}`);
        } else {
            console.error("No valid PDF link selected");
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const transform = (slot) => ({
        ...slot,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    const filterTree = (nodes, query) => {
        if (!query) return nodes;

        const matchQuery = (name) => name.toLowerCase().includes(query.toLowerCase());

        const filteredChildren = nodes.children
            .map(child => filterTree(child, query))
            .filter(child => child !== null);

        if (matchQuery(nodes.folderName) || filteredChildren.length > 0 || (nodes.link && matchQuery(nodes.folderName))) {
            return {
                ...nodes,
                children: filteredChildren
            };
        }

        return null;
    };

    const filteredSearchTree = folderTree
        .map(node => filterTree(node, searchQuery))
        .filter(node => node !== null);

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
    }

    if (error) {
        return <Typography color="error" align="center">{error}</Typography>;
    }

    return (
        <Box height="100vh" width="100%" display="flex" flexDirection="row" gap={2} p={2}>
            {/* Tree View Container */}
            <Box
                width="35%"
                display="flex"
                flexDirection="column"
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    padding: '16px',
                    backgroundColor: 'white',
                    height: 'calc(100vh - 120px)',
                    boxSizing: 'border-box'
                }}
                className="custom-scrollBar"
            >
                {/* Search Box */}
                <TextField
                    fullWidth
                    label={getTranslationVariantText("_search", "label")} // Translated label for search
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ marginBottom: '16px' }}
                />

                {/* Folder Tree */}
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }} className="custom-scrollBar">
                    <SimpleTreeView>
                        {filteredSearchTree.map(node => renderTree(node, handleFileClick, toggleFolder, handleEllipsisClick))}
                    </SimpleTreeView>
                </Box>
            </Box>

            {/* PDF Viewer Container */}
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    padding: '16px',
                    backgroundColor: 'white',
                    height: 'calc(100vh - 120px)',
                    boxSizing: 'border-box',
                    overflow: 'auto'
                }}
            >
                {pdfLink ? (
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                        <div
                            className="rpv-core__viewer"
                            style={{
                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                boxSizing: 'border-box',
                                overflow: 'auto'
                            }}
                        >
                            <div
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: '#eeeeee',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    padding: '0.25rem',
                                    boxSizing: 'border-box'
                                }}
                            >
                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    overflow: 'auto',
                                    boxSizing: 'border-box'
                                }}
                                className="custom-scrollBar"
                            >
                                <Viewer
                                    fileUrl={pdfLink}
                                    plugins={[toolbarPluginInstance]}
                                />
                            </div>
                        </div>
                    </Worker>
                ) : (
                    <Typography align="center" sx={{ marginTop: '20px' }}>
                        {getTranslationVariantText("preview-document.select", "label")} {/* Translated message when no file is selected */}
                    </Typography>
                )}
            </Box>

            {/* Ellipsis menu for chat with PDF */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleChatWithPdf}>
                    {getTranslationVariantText("chat-with-pdf.title", "label")} {/* Translated button for "Chat with PDF" */}
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default PreviewDocumentDataWrapper;
